import { all, fork, put, call, takeEvery, take, cancel } from "redux-saga/effects";
import { actions } from "../reducers/global";
import { GLOBAL_DATA_KEY } from "../constants";
import { rsfDB } from "../lib/firebase"; // call은 동기, fork는 비동기 요청
function* syncGlobalData() { const task = yield fork(rsfDB.syncDocument, "Global/".concat(GLOBAL_DATA_KEY), { successActionCreator: data => ({ type: actions.SYNC_GLOBAL_DATA_SUCCESS, data: data.data() }), failureActionCreator: err => ({ type: actions.SYNC_GLOBAL_DATA_FAILURE, err: err.message }) }); yield take(actions.SYNC_GLOBAL_DATA_DONE); yield cancel(task); }
function* getGlobalData(action) { const { exhibitionId } = action; try {
    var _exhibitionSnapshot$d, _exhibitionSnapshot$d2;
    const globalSnapshot = yield call(rsfDB.getDocument, "Global/".concat(GLOBAL_DATA_KEY));
    const exhibitionSnapshot = yield call(rsfDB.getDocument, "Exhibition/".concat(exhibitionId));
    yield put({ type: actions.GET_GLOBAL_DATA_SUCCESS, globalData: globalSnapshot.data(), hasMenuButton: exhibitionSnapshot.data().hasMenuButton, isCustomized: exhibitionSnapshot.data().isCustomized, hasTitle: exhibitionSnapshot.data().hasTitle, hasView: exhibitionSnapshot.data().hasView, hasSize: exhibitionSnapshot.data().hasSize, hasTag: exhibitionSnapshot.data().hasTag, hasMenuOptions: exhibitionSnapshot.data().hasMenuOptions, globalOption: exhibitionSnapshot.data().defaultGlobalOptions, queryOption: exhibitionSnapshot.data().queryOption, isPlatform: exhibitionSnapshot.data().isPlatform, plan: exhibitionSnapshot.data().plan, expiredAt: ((_exhibitionSnapshot$d = exhibitionSnapshot.data().expiredAt) === null || _exhibitionSnapshot$d === void 0 ? void 0 : _exhibitionSnapshot$d.seconds) * 1000, paidAt: ((_exhibitionSnapshot$d2 = exhibitionSnapshot.data().paidAt) === null || _exhibitionSnapshot$d2 === void 0 ? void 0 : _exhibitionSnapshot$d2.seconds) * 1000 });
}
catch (err) {
    yield put({ type: actions.GET_GLOBAL_DATA_FAILURE, error: err.message });
} }
function* updateGlobalData(action) { const { target, value } = action; try {
    yield call(rsfDB.updateDocument, "Global/".concat(GLOBAL_DATA_KEY), target, value);
    yield put({ type: actions.UPDATE_GLOBAL_DATA_SUCCESS });
}
catch (err) {
    yield put({ type: actions.UPDATE_GLOBAL_DATA_FAILURE, error: err.message });
} }
function* watchSyncGlobalData() { yield takeEvery(actions.SYNC_GLOBAL_DATA_REQUEST, syncGlobalData); }
function* watchGetGlobalData() { yield takeEvery(actions.GET_GLOBAL_DATA_REQUEST, getGlobalData); }
function* watchUpdateGlobalData() { yield takeEvery(actions.UPDATE_GLOBAL_DATA_REQUEST, updateGlobalData); }
export default function* globalSaga() { yield all([fork(watchSyncGlobalData), fork(watchUpdateGlobalData), fork(watchGetGlobalData)]); }
