import { enableAllPlugins } from 'immer';
import { produce } from 'immer';
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from '../utils/reducerUtils';
import { i18n } from '../plugins/i18next';
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState = { avatars: {}, isAdminMode: false, clientId: null, currentAction: [{ type: 'none', select: 'none', value: [], title: '' }], measurementMode: false, playingMedia: { type: 'none', title: '', isPlaying: false, playingComponentCount: 0, id: '', duration: 0, currentTime: 0 }, vrtype: 'null', vrId: null, roomId: null, globalData: { value: '', isWebLinkActive: false, posterLink: '', posterLinkEn: '', guideTextEn: '', guideText: '' }, menuButton: false,
    hasSize: true, hasView: true, hasIntro: false, screen: { width: null, orientation: window.screen.orientation ? window.screen.orientation.type.split('-')[0] : 'landscape' }, hasMenuButton: false, hasTitle: true, hasTag: false, isSpaceLoaded: false, isBGMLoaded: false, hasMenuOptions: { infoButton: true, chatButton: true, BGMButton: true, fullScreenButton: true // detailView: boolean;
    }, queryOption: { vr: false, dh: false, lang: 'ko', play: true, title: false }, globalOption: { infoButton: false, chatButton: false, BGMButton: true, webLinkModal: false, fullScreenButton: false, detailView: false,
        videoModal: false,
        componentPlaying: false, highlightButton: false, isObjectHovered: false, isSweepVisible: false, isHighDefinitionMode: false, language: i18n.language, pdfViewModal: false, detailedInfo: false, survey: false }, currentWebLink: null, wasPlayingMusic: false, playingComponentCount: 0,
    syncGlobalDataStatus: createStatus(), getGlobalDataStatus: createStatus(), updateGlobalDataStatus: createStatus() }; // 액션들의 집합.
export const actions = {
    SET_VR_TYPE: 'SET_VR_TYPE', SET_AVATARS: 'SET_AVATARS',
    SYNC_GLOBAL_DATA_REQUEST: 'SYNC_GLOBAL_DATA_REQUEST', SYNC_GLOBAL_DATA_SUCCESS: 'SYNC_GLOBAL_DATA_SUCCESS', SYNC_GLOBAL_DATA_FAILURE: 'SYNC_GLOBAL_DATA_FAILURE', GET_GLOBAL_DATA_REQUEST: 'GET_GLOBAL_DATA_REQUEST', GET_GLOBAL_DATA_SUCCESS: 'GET_GLOBAL_DATA_SUCCESS', GET_GLOBAL_DATA_FAILURE: 'GET_GLOBAL_DATA_FAILURE', UPDATE_GLOBAL_DATA_REQUEST: 'UPDATE_GLOBAL_DATA_REQUEST', UPDATE_GLOBAL_DATA_SUCCESS: 'UPDATE_GLOBAL_DATA_SUCCESS', UPDATE_GLOBAL_DATA_FAILURE: 'UPDATE_GLOBAL_DATA_FAILURE', UPDATE_GLOBAL_DATA_STATUS_RESET: 'UPDATE_GLOBAL_DATA_STATUS_RESET', SYNC_GLOBAL_DATA_DONE: 'SYNC_GLOBAL_DATA_DONE', SYNC_GET_POINTS_DONE: 'SYNC_GET_POINTS_DONE', TOGGLE_GLOBAL_OPTION_CHANGE: 'TOGGLE_GLOBAL_OPTION_CHANGE', TOGGLE_MENU_BUTTON: 'TOGGLE_MENU_BUTTON', SET_SCREEN_SIZE: 'SET_SCREEN_SIZE', INCREASE_PLAYING_COMPONENT_COUNT: 'INCREASE_PLAYING_COMPONENT_COUNT', SET_WAS_PLAYING_BGM: 'SET_WAS_PLAYING_BGM', SET_CURRENT_WEB_LINK: 'SET_CURRENT_WEB_LINK', SET_SPACE_LOADED: 'SET_SPACE_LOADED', SET_BGM_LOADED: 'SET_BGM_LOADED', SET_CLIENT_ID: 'SET_CLIENT_ID', SET_ACTION: 'SET_ACTION', PUSH_ACTION: 'PUSH_ACTION', POP_ACTION: 'POP_ACTION', TOGGLE_MEASUREMENT_MODE: 'TOGGLE_MEASUREMENT_MODE', TOGGLE_ADMIN_MODE: 'TOGGLE_ADMIN_MODE', PLAY_MEDIA: 'PLAYING_MEDIA', STOP_MEDIA: 'STOP_MEDIA', PAUSE_MEDIA: 'PAUSE_MEDIA', REPLAY_MEDIA: 'REPLAY_MEDIA', SET_MEDIA_TIME: 'SET_MEDIA_TIME', TOGGLE_INSTALLED_OBJECT_MODAL: 'TOGGLE_INSTALLED_OBJECT_MODAL', SET_POSITION_OPTION: 'SET_POSITION_OPTION'
};
export const setVRTypeAction = (vrtype, vrId, roomId) => ({ type: actions.SET_VR_TYPE, vrtype, vrId, roomId });
export const setAvatarsAction = avatars => ({ type: actions.SET_AVATARS, avatars });
export const setClientIdAction = clientId => ({ type: actions.SET_CLIENT_ID, clientId }); // 액션 크리에이터. dispatch 내부에서 사용.
export const getGlobalDataAction = exhibitionId => ({ type: actions.GET_GLOBAL_DATA_REQUEST, exhibitionId });
export const increasePlayingComponent = () => ({ type: actions.INCREASE_PLAYING_COMPONENT_COUNT }); //인자로 가진 globalOption을 켜고 끄는 액션
export const toggleGlobalOptionChangeAction = function (target) { let value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null; return { type: actions.TOGGLE_GLOBAL_OPTION_CHANGE, target, value }; };
export const toggleMenuButtonAction = function () { let forced = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false; return { type: actions.TOGGLE_MENU_BUTTON, forced }; };
export const syncGlobalDataAction = () => ({ type: actions.SYNC_GLOBAL_DATA_REQUEST });
export const updateGlobalDataAction = (target, value) => ({ type: actions.UPDATE_GLOBAL_DATA_REQUEST, target, value });
export const syncGlobalDataDoneAction = () => ({ type: actions.SYNC_GLOBAL_DATA_DONE });
export const resetStatusAction = type => ({ type });
export const setScreenSizeAction = (width, orientation) => ({ type: actions.SET_SCREEN_SIZE, width, orientation: window.screen.orientation ? window.screen.orientation.type.split('-')[0] : 'landscape' });
export const setWasPlayingBGMAction = value => ({ type: actions.SET_WAS_PLAYING_BGM, value });
export const setCurrentWebLink = data => ({ type: actions.SET_CURRENT_WEB_LINK, data });
export const setSpaceLoaded = () => ({ type: actions.SET_SPACE_LOADED });
export const setBGMLoaded = () => ({ type: actions.SET_BGM_LOADED });
export const setAction = action => ({ type: actions.SET_ACTION, action });
export const pushAction = action => ({ type: actions.PUSH_ACTION, action });
export const popAction = () => ({ type: actions.POP_ACTION });
export const toggleMeasurementModeAction = value => ({ type: actions.TOGGLE_MEASUREMENT_MODE, value });
export const toggleAdminMode = bool => ({ type: actions.TOGGLE_ADMIN_MODE, bool });
export const playMediaAction = (type, id, title) => ({ type: actions.PLAY_MEDIA, payload: { type, id, title } });
export const stopMediaAction = () => ({ type: actions.STOP_MEDIA });
export const pauseMediaAction = () => ({ type: actions.PAUSE_MEDIA });
export const replayMediaAction = () => ({ type: actions.REPLAY_MEDIA });
export const setMediaTimeAction = (currentTime, duration) => ({ type: actions.SET_MEDIA_TIME, payload: { currentTime, duration } }); // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = function () {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    let action = arguments.length > 1 ? arguments[1] : undefined;
    return produce(state, draft => {
        var _action$hasSize, _action$hasTag, _action$hasMenuOption, _action$hasMenuOption2, _action$hasMenuOption3, _action$hasMenuOption4, _action$hasMenuOption5, _action$hasMenuOption6, _action$hasMenuOption7, _action$hasMenuOption8, _action$globalOption$, _action$globalOption, _action$globalOption$2, _action$globalOption2, _action$globalOption$3, _action$globalOption3, _action$globalOption$4, _action$globalOption4;
        switch (action.type) {
            case actions.SET_CLIENT_ID:
                draft.clientId = action.clientId;
                break;
            case actions.TOGGLE_ADMIN_MODE:
                if (typeof action.bool !== 'undefined') {
                    draft.isAdminMode = action.bool;
                }
                else {
                    draft.isAdminMode = !draft.isAdminMode;
                }
                break;
            case actions.SET_VR_TYPE:
                draft.vrtype = action.vrtype;
                draft.vrId = action.vrId;
                draft.roomId = action.roomId;
                break;
            case actions.SET_AVATARS:
                Object.entries(action.avatars).forEach(_ref => { let [id, avatar] = _ref; draft.avatars[id] = avatar; });
                break;
            case actions.SYNC_GLOBAL_DATA_REQUEST:
                draft.syncGlobalDataStatus = createRequestStatus();
                break;
            case actions.SYNC_GLOBAL_DATA_SUCCESS:
                draft.syncGlobalDataStatus = createSuccessStatus();
                draft.globalData = action.data;
                break;
            case actions.SYNC_GLOBAL_DATA_FAILURE:
                draft.syncGlobalDataStatus = createFailureStatus(action.error);
                break;
            case actions.GET_GLOBAL_DATA_REQUEST:
                draft.getGlobalDataStatus = createRequestStatus();
                break;
            case actions.GET_GLOBAL_DATA_SUCCESS:
                draft.getGlobalDataStatus = createSuccessStatus();
                draft.globalData = action.globalData;
                draft.hasSize = (_action$hasSize = action.hasSize) !== null && _action$hasSize !== void 0 ? _action$hasSize : true;
                draft.hasTag = (_action$hasTag = action.hasTag) !== null && _action$hasTag !== void 0 ? _action$hasTag : false;
                draft.hasMenuOptions.infoButton = (_action$hasMenuOption = (_action$hasMenuOption2 = action.hasMenuOptions) === null || _action$hasMenuOption2 === void 0 ? void 0 : _action$hasMenuOption2.infoButton) !== null && _action$hasMenuOption !== void 0 ? _action$hasMenuOption : true;
                draft.hasMenuOptions.chatButton = (_action$hasMenuOption3 = (_action$hasMenuOption4 = action.hasMenuOptions) === null || _action$hasMenuOption4 === void 0 ? void 0 : _action$hasMenuOption4.chatButton) !== null && _action$hasMenuOption3 !== void 0 ? _action$hasMenuOption3 : true;
                draft.hasMenuOptions.BGMButton = (_action$hasMenuOption5 = (_action$hasMenuOption6 = action.hasMenuOptions) === null || _action$hasMenuOption6 === void 0 ? void 0 : _action$hasMenuOption6.BGMButton) !== null && _action$hasMenuOption5 !== void 0 ? _action$hasMenuOption5 : true;
                draft.hasMenuOptions.fullScreenButton = (_action$hasMenuOption7 = (_action$hasMenuOption8 = action.hasMenuOptions) === null || _action$hasMenuOption8 === void 0 ? void 0 : _action$hasMenuOption8.fullScreenButton) !== null && _action$hasMenuOption7 !== void 0 ? _action$hasMenuOption7 : true;
                draft.globalOption.infoButton = (_action$globalOption$ = (_action$globalOption = action.globalOption) === null || _action$globalOption === void 0 ? void 0 : _action$globalOption.infoButton) !== null && _action$globalOption$ !== void 0 ? _action$globalOption$ : false;
                draft.globalOption.BGMButton = (_action$globalOption$2 = (_action$globalOption2 = action.globalOption) === null || _action$globalOption2 === void 0 ? void 0 : _action$globalOption2.BGMButton) !== null && _action$globalOption$2 !== void 0 ? _action$globalOption$2 : true;
                draft.globalOption.chatButton = (_action$globalOption$3 = (_action$globalOption3 = action.globalOption) === null || _action$globalOption3 === void 0 ? void 0 : _action$globalOption3.chatButton) !== null && _action$globalOption$3 !== void 0 ? _action$globalOption$3 : true;
                draft.globalOption.fullScreenButton = (_action$globalOption$4 = (_action$globalOption4 = action.globalOption) === null || _action$globalOption4 === void 0 ? void 0 : _action$globalOption4.fullScreenButton) !== null && _action$globalOption$4 !== void 0 ? _action$globalOption$4 : false;
                draft.globalOption.language = i18n.language;
                draft.hasTitle = true;
                if (action.queryOption)
                    draft.queryOption = Object.assign(Object.assign({}, draft.queryOption), action.queryOption);
                if (action.isPlatform) {
                    draft.hasMenuButton = true;
                    draft.globalOption.BGMButton = true;
                    draft.hasTitle = true;
                    draft.hasView = true;
                }
                if (action.isCustomized) {
                    var _action$globalOption$5, _action$globalOption5, _action$hasMenuButton, _action$hasTitle, _action$globalOption$6, _action$globalOption6, _action$globalOption$7, _action$globalOption7;
                    draft.globalOption.BGMButton = (_action$globalOption$5 = (_action$globalOption5 = action.globalOption) === null || _action$globalOption5 === void 0 ? void 0 : _action$globalOption5.BGMButton) !== null && _action$globalOption$5 !== void 0 ? _action$globalOption$5 : true;
                    draft.hasMenuButton = (_action$hasMenuButton = action.hasMenuButton) !== null && _action$hasMenuButton !== void 0 ? _action$hasMenuButton : true;
                    draft.hasTitle = (_action$hasTitle = action.hasTitle) !== null && _action$hasTitle !== void 0 ? _action$hasTitle : true;
                    draft.globalOption.isSweepVisible = (_action$globalOption$6 = (_action$globalOption6 = action.globalOption) === null || _action$globalOption6 === void 0 ? void 0 : _action$globalOption6.isSweepVisible) !== null && _action$globalOption$6 !== void 0 ? _action$globalOption$6 : false;
                    draft.globalOption.isHighDefinitionMode = (_action$globalOption$7 = (_action$globalOption7 = action.globalOption) === null || _action$globalOption7 === void 0 ? void 0 : _action$globalOption7.isHighDefinitionMode) !== null && _action$globalOption$7 !== void 0 ? _action$globalOption$7 : false; // highDefinitionMode은 모바일에서 실행될 수 없음.
                    // if (action.globalOption?.isHighDefinitionMode !== null) {
                    //   draft.globalOption.isHighDefinitionMode = !isMobile() && action.globalOption.isHighDefinitionMode;
                    // }
                } // query string, search 추가함
                // chat, bgm, hd 으로 초기설정 가능하며,
                // title, menu로 영구 설정 가능함.
                const search = new URLSearchParams(window.location.search);
                if (search.get('chat') === '0') {
                    draft.globalOption.chatButton = false;
                }
                if (search.get('bgm') === '0') {
                    draft.globalOption.BGMButton = false;
                }
                if (search.get('hd') === '1') {
                    draft.globalOption.isHighDefinitionMode = true;
                }
                if (search.get('title') === '0') {
                    draft.hasTitle = true;
                }
                if (search.get('menu') === '0') {
                    draft.globalOption.chatButton = false;
                    draft.hasMenuButton = false;
                    draft.globalOption.BGMButton = false;
                }
                break;
            case actions.GET_GLOBAL_DATA_FAILURE:
                draft.getGlobalDataStatus = createFailureStatus(action.error);
                break;
            case actions.UPDATE_GLOBAL_DATA_REQUEST:
                draft.updateGlobalDataStatus = createRequestStatus();
                break;
            case actions.UPDATE_GLOBAL_DATA_SUCCESS:
                draft.updateGlobalDataStatus = createSuccessStatus();
                break;
            case actions.UPDATE_GLOBAL_DATA_FAILURE:
                draft.updateGlobalDataStatus = createFailureStatus(action.error);
                break;
            case actions.UPDATE_GLOBAL_DATA_STATUS_RESET:
                draft.updateGlobalDataStatus = resetStatus();
                break;
            case actions.TOGGLE_GLOBAL_OPTION_CHANGE:
                if (action.value !== null) {
                    draft.globalOption[action.target] = action.value;
                }
                else {
                    draft.globalOption[action.target] = !draft.globalOption[action.target];
                }
                break;
            case actions.TOGGLE_MENU_BUTTON:
                draft.menuButton = !draft.menuButton;
                break;
            case actions.SET_SCREEN_SIZE:
                draft.screen.width = action.width;
                if (action.width === 'xs' && document.body.clientHeight > document.body.clientWidth) {
                    draft.screen.orientation = 'portrait';
                }
                else
                    draft.screen.orientation = action.orientation;
                break;
            case actions.INCREASE_PLAYING_COMPONENT_COUNT:
                draft.playingComponentCount += 1;
                break;
            case actions.SET_WAS_PLAYING_BGM:
                draft.wasPlayingMusic = action.value;
                break;
            case actions.SET_CURRENT_WEB_LINK:
                draft.currentWebLink = action.data;
                break;
            case actions.SET_SPACE_LOADED:
                draft.isSpaceLoaded = true;
                break;
            case actions.SET_BGM_LOADED:
                draft.isBGMLoaded = true;
                break;
            case actions.SET_ACTION:
                draft.currentAction[0] = action.action;
                break;
            case actions.PUSH_ACTION:
                draft.currentAction.push(action.action);
                break;
            case actions.POP_ACTION:
                if (draft.currentAction.length > 1) {
                    draft.currentAction.pop();
                }
                else {
                    draft.currentAction[0] = { type: 'none', select: 'none', value: [], title: '' };
                }
                break;
            case actions.TOGGLE_MEASUREMENT_MODE:
                if (action.value !== undefined) {
                    draft.measurementMode = action.value;
                }
                else {
                    draft.measurementMode = !draft.measurementMode;
                }
                break;
            case actions.PLAY_MEDIA:
                draft.playingMedia.type = action.payload.type;
                draft.playingMedia.title = action.payload.title;
                draft.playingMedia.id = action.payload.id;
                draft.playingMedia.isPlaying = true;
                draft.playingMedia.playingComponentCount += 1;
                break;
            case actions.STOP_MEDIA:
                draft.playingMedia.type = 'none';
                draft.playingMedia.title = '';
                draft.playingMedia.id = '';
                draft.playingMedia.isPlaying = false;
                draft.playingMedia.playingComponentCount += 1;
                break;
            case actions.PAUSE_MEDIA:
                draft.playingMedia.isPlaying = false;
                draft.playingMedia.playingComponentCount += 1;
                break;
            case actions.REPLAY_MEDIA:
                draft.playingMedia.isPlaying = true;
                draft.playingMedia.playingComponentCount += 1;
                break;
            case actions.SET_MEDIA_TIME:
                draft.playingMedia.currentTime = action.payload.currentTime;
                draft.playingMedia.duration = action.payload.duration;
                break;
            default: break;
        }
    });
};
export default reducer;
