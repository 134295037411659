import axios from "axios";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "../reducers/room"; // call은 동기, fork는 비동기 요청
function* getRooms(action) {
    const { exhibitionId } = action; // global.ts 에서 vrType 을 받아옴
    const vrtype = yield select(state => state.global.vrtype); // vrtype에 따라 다른 url을 호출
    let url = "";
    if (vrtype === "LINC") {
        url = "https://asia-northeast3-onna-builder-linc.cloudfunctions.net/getRoomData";
    }
    else if (vrtype === "HIVE") {
        url = "https://asia-northeast3-onna-hive.cloudfunctions.net/getRoomData";
    }
    else {
        url = "https://asia-northeast3-onna-b7783.cloudfunctions.net/getRoomData";
    }
    try {
        const res = yield axios.post(url, { id: exhibitionId });
        const data = res.data.data;
        yield put({ type: actions.GET_ROOMS_SUCCESS, data });
    }
    catch (err) {
        yield put({ type: actions.GET_ROOMS_FAILURE, error: err.message });
    }
}
function* watchGetRoom() { yield takeLatest(actions.GET_ROOMS_REQUEST, getRooms); }
export default function* globalSaga() { yield all([fork(watchGetRoom)]); }
