import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ENABLE_REDUX_DEV_TOOLS } from "../constants";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({ reducer: rootReducer, devTools: ENABLE_REDUX_DEV_TOOLS, middleware: [...getDefaultMiddleware(), sagaMiddleware] });
sagaMiddleware.run(rootSaga);
export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();
export default store;
