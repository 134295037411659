import { enableAllPlugins } from 'immer';
import { produce } from 'immer';
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState = { roomId: '', roomType: 'guest', guestKey: '', hostKey: '' }; // 액션들의 집합.
export const actions = { SET_ROOM_DATA: 'SET_ROOM_DATA', SET_ROOM_INFO: 'SET_ROOM_INFO' }; // 액션 크리에이터. dispatch 내부에서 사용.
export const saveRoomIdAction = (roomId, roomType) => ({ type: actions.SET_ROOM_DATA, roomId, roomType });
export const setRoomInfoAction = (roomId, roomType, guestKey, hostKey) => ({ type: actions.SET_ROOM_INFO, roomId, roomType, guestKey, hostKey }); // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = function () { let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; let action = arguments.length > 1 ? arguments[1] : undefined; return produce(state, draft => { switch (action.type) {
    case actions.SET_ROOM_DATA:
        draft.roomId = action.roomId;
        draft.roomType = action.roomType;
        break;
    case actions.SET_ROOM_INFO:
        draft.roomId = action.roomId;
        draft.roomType = action.roomType;
        draft.guestKey = action.guestKey;
        draft.hostKey = action.hostKey;
        break;
    default: break;
} }); };
export default reducer;
